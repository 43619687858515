import './App.css';
import 'iconify-icon';
import ImgSlider from './ImgSlider';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Photo from './Photo';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    // Simulate a loading delay or perform any async operations needed
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust this delay as needed

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {isLoading ? (
          <ReactLoading type={'spinningBubbles'} color={'#dadada'} />
        ) : (
          <div className="App-Content">
            <img src="/img/logo.png" className="App-logo" alt="Logo" />
            <p>CAPTURE YOUR MOMENTS & LISTEN TO YOUR MEMORIES</p>
            <ImgSlider />
            <label className="icon-container">
              <Photo setIsProcessing={setIsProcessing} />
            </label>
            <p className='try-text'>Try it out</p>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
