import React, { useEffect, useRef, useState } from 'react';
import './App.css'; // Assuming your CSS is in App.css

const ImgSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageContainerRef = useRef(null);
  //const spotifyRef = useRef(null); // Reference to the Spotify iframe

  const images = [
    '/img/orange-sunset.jpg',
    '/img/blue-clubbing.jpg',
    '/img/white-wedding.jpg',
    '/img/brown-barber.jpg',
    '/img/gold-rye.jpg',
  ];

  const songs = [
    'https://open.spotify.com/embed/track/1gXBi2I04CLJkTQnhNfEJT',
    'https://open.spotify.com/embed/track/0NPgN33t7QlDKB5Ciw7CZJ',
    'https://open.spotify.com/embed/track/1HNkqx9Ahdgi1Ixy2xkKkL',
    'https://open.spotify.com/embed/track/6bEacQTThrw9P1RiIBGs7P',
    'https://open.spotify.com/embed/track/3PZAaFR5G3VEcrz2AljA41',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 30000); // 30 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  useEffect(() => {
    const scrollPosition = currentIndex * imageContainerRef.current.clientWidth;
    imageContainerRef.current.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    });

    // Update the Spotify player when the image changes
    //updateSpotifyPlayer();

  }, [currentIndex]);

  /*
  const updateSpotifyPlayer = () => {
    if (spotifyRef.current) {
      const spotifyEmbedWindow = spotifyRef.current.contentWindow;

      // Change the src of the iframe to load the new song
      spotifyRef.current.src = songs[currentIndex];

      // Wait until the iframe is fully loaded, then send the play command
      spotifyRef.current.onload = () => {
        spotifyEmbedWindow.postMessage({ command: 'toggle' }, '*');
      };
    }
  };*/

  return (
    <div className='imgslider'>
      <div id="imageContainer" className="image-container" ref={imageContainerRef}>
        {images.map((src, index) => (
          <img key={index} className="slider-img" alt={`slide-${index}`} src={src} />
        ))}
      </div>
      <div className='spotify' id="spotify">
        <iframe
          //ref={spotifyRef}
          src={songs[currentIndex]}
          width="300"
          height="80"
          frameBorder="0"
          allow="encrypted-media"
          allowTransparency="true"
          title={`Spotify Player ${currentIndex}`}
        ></iframe>
      </div>
    </div>
  );
};

export default ImgSlider;
