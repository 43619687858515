import React, { useState } from 'react';
import icon from './data/img/camera.gif';
import loadingIcon from './data/img/loading.gif';
import playIcon from './data/img/play.gif';

function Photo({ setIsProcessing }) {
    const [playlistLink, setPlaylistLink] = useState(null);
    const [currentIcon, setCurrentIcon] = useState(icon); // State to manage the icon

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            // Indicate that processing has started and change icon to loadingIcon
            setIsProcessing(true);
            setCurrentIcon(loadingIcon);

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await fetch('https://api.musaic.click/recommend_music/', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    setPlaylistLink(result.playlist_url); // Assuming the API returns the playlist URL
                    setCurrentIcon(playIcon); // Change icon to playIcon after processing
                } else {
                    console.error('Error uploading image:', response.statusText);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                // Indicate that processing has finished
                setIsProcessing(false);
            }
        }
    };

    const handleIconClick = () => {
        if (playlistLink) {
            window.open(playlistLink, '_blank', 'noopener,noreferrer'); // Open link in a new tab
        }
    };

    return (
        <div>
            <input
                className="file-input"
                type="file"
                accept="image/*"
                capture="camera"
                onChange={handleImageUpload}
            />
            <img
                id="icon"
                src={currentIcon} // Use currentIcon state to determine the icon
                alt="camera-icon"
                width="32"
                height="32"
                style={{ color: '#121212', cursor: playlistLink ? 'pointer' : 'default' }}
                onClick={handleIconClick} // Open the link when the icon is clicked
            />
        </div>
    );
}

export default Photo;
